<template>
    <div class="Coupon">
          <div class="item-header flex">

            <!-- <div class="screen-list-item flex">
                <span class="screen-item-label">兑换内容</span>
                <a-select style="width: 200px">
                  
                </a-select>
            </div> -->

            <div class="screen-list-item flex">
                <span class="screen-item-label">兑换类别</span>
                <a-select :value="serachParams.exchangeType.toString()" style="width: 200px" @change="onChangExchangetype">
                  <a-select-option value="0">全部</a-select-option>
                  <a-select-option value="1">核心课</a-select-option>
                  <a-select-option value="2">拓展</a-select-option>
                  <a-select-option value="4">AI体验课</a-select-option>
                  <a-select-option value="5">AI系统课</a-select-option>
                </a-select>
            </div>

            <div class="screen-list-item flex">
                <span class="screen-item-label">兑换码期限</span>
                <a-range-picker format="YYYY-MM-DD" style="width: 350px;" :placeholder="['开始时间','结束时间']" allowClear @change="changeTime"/>
            </div>

            <div class="screen-list-item flex">
                <span class="screen-item-label">状态</span>
                <a-select :value="serachParams.codePackageState.toString()" style="width: 200px" @change="onChangCodePackageState">
                  <a-select-option value="0">全部</a-select-option>
                  <a-select-option value="1">未开始</a-select-option>
                  <a-select-option value="2">进行中</a-select-option>
                  <a-select-option value="3">已过期</a-select-option>
                  <a-select-option value="4">已停用</a-select-option>
                </a-select>
            </div>

            <div class="screen-list-item flex">
                <span class="screen-item-label">兑换码名称</span>
                <a-input placeholder="兑换码名称" style="width: 200px;" v-model="serachParams.codePackageName"/>
            </div>
            
            <div class="screen-list-item">
                <a-button style="margin-right:20px" @click="resetBtn()">重置</a-button>
                <a-button type="primary" @click="serachBtn()">查询</a-button>
            </div>

            <div>
                <a-button type="primary" @click="onTtableClick" ><a-icon type="plus" />新建兑换码</a-button>
            </div>
        </div>

        <div class="coupon-content" :style="{'min-height':minHeight}">
            <div style="margin-top:15px;">
                <a-table :columns="columns" :dataSource="infoData" :pagination="false" bordered>
                    
                    <div slot="action" slot-scope="record">
                        <a-popover placement="bottom" v-if="record.code_package_state != 4">
                            <template slot="content" >
                              <div class="edit-item">
                                  <a href="#" @click="_showEdit(record.code_package_id,false)">编辑</a>
                              </div>
                              <div class="edit-item">
                                    <a href="#" @click="_updateCodePackageState(record,4)">停止</a>
                              </div>
                              <div class="edit-item" v-if="record.code_package_state == 1">
                                  <a href="#" @click="_updateCodePackageState(record,4)">开始</a>
                              </div>
                            </template>
                            <a-icon type="dash" class="smalldash" @click.stop/>
                        </a-popover>
                        <div class="edit-item" v-else>
                          <a href="#" @click="_showEdit(record.code_package_id,true)">查看</a>
                        </div>
                    </div>

                    <div slot="send_count" slot-scope="text, record, index">
                        <!-- <span style="color: #40a9ff;font-weight: 400;">{{text}}</span> -->
                        <a style="color: #40a9ff;font-weight: 400;" href="javascript:void(0);" @click="_showCodeList(record,0)">{{text}}</a>
                    </div>

                    <div slot="use_count" slot-scope="text, record, index">
                        <!-- <span style="color: #40a9ff;font-weight: 400;">{{text}}</span> -->
                        <a style="color: #40a9ff;font-weight: 400;" href="javascript:void(0);" @click="_showCodeList(record,2)">{{text}}</a>
                    </div>

                </a-table> 
            </div>
            <div style="margin: 20px 0;text-align: right;" v-if="total > 0">
                <a-pagination :defaultCurrent="1" :total="total" @change="pageChange" />
            </div>
        </div>

        <a-drawer :title="codeListTitle" placement="right" :closable="false" width="1200" @close="_hideCodeList" :visible="showCodeListState">
            <div class="code-list">
                <div class="code-serach-list">
                    <a-select :value="codeListSerachParams.codeState.toString()" placeholder="状态" style="width: 200px" @change="_onCodeListStateChange">
                      <a-select-option value="0">全部</a-select-option>
                      <a-select-option value="1">未兑换</a-select-option>
                      <a-select-option value="2">已兑换</a-select-option>
                      <a-select-option value="3">已停用</a-select-option>
                    </a-select>

                    <a-input placeholder="兑换码" style="width: 200px;margin-left: 10px;margin-right: 10px;" v-model="codeListSerachParams.codeName"/>

                    <a-button type="primary" @click="codeSerach" style="margin-right: 10px;">查询</a-button>
                    <a-button type="primary" @click="expert_code">导出兑换码</a-button>
                </div>

                <div style="margin-top:15px;">
                    <a-table :columns="codeListColumns" :dataSource="codeListData" :pagination="false" bordered>
                        <div slot="action" slot-scope="text, record, index">
                            <a v-if="record.code_state == 1" style="color: #40a9ff;font-weight: 400;" href="javascript:void(0);" @click="_codeStop(record)">停用</a>
                        </div>
                    </a-table> 
                    <div style="margin: 20px 0;text-align: right;" v-if="codeListTotal > 10">
                        <a-pagination v-model="codeListSerachParams.page" :total="codeListTotal" show-less-items @change="onChangeCodeListPage"/>
                    </div>
                </div>
            </div>
        </a-drawer>

        <a-drawer title="编辑兑换码" placement="right" :closable="false" width="900" @close="_hideEditCancel" :visible="ExChangeEditVisible">
            <QmExchangeEdit :codePackageId="codePackageId" @fathermethod="_fathermethod"></QmExchangeEdit>
        </a-drawer>

        <a-drawer title="新建兑换码" placement="right" :closable="false" width="900" @close="_hideCancel" :visible="ExChangeVisible">
             <div class="item-list">
                 <label>兑换码名称:</label>
                 <div class="item-content">
                     <a-input v-model="codePackageName" placeholder="请输入兑换码名称"/>
                 </div>
             </div>
             <div class="item-list">
                 <label>发放数量:</label>
                 <div class="item-content">
                     <a-input v-model="sendCount" placeholder="请输入发放数量"/>
                 </div>
             </div>
             <div class="item-list">
                 <label>使用时间:</label>
                 <div class="item-content">
                     <a-range-picker format="YYYY-MM-DD" :placeholder="['开始时间','结束时间']" allowClear @change="onChangeUseTime" />
                 </div>
             </div>
             <div class="item-list">
                 <label>发放渠道:</label>
                 <div class="item-content">
                     <a-select  style="width: 200px" @change="onChangeChannel">
                      <a-select-option value="0">全部</a-select-option>
                      <a-select-option v-for="(item,index) in channelList" :value="item.simple_id">{{item.simple_name}}</a-select-option>
                    </a-select>
                 </div>
             </div>
             <div class="item-list">
                 <label>发放内容:</label>
                 <div class="item-content">
                     <a-tabs :activeKey="activeKey" @change="_get_tabs_data" style="width: 600px;">
                      <a-tab-pane key="3" tab="AI课">
                        <a-tabs default-active-key="0" @change="aiChange">
                          <a-tab-pane v-for="(item, index) in aiCourseSetting" :key="index" :tab="item.title">
                              <div class="aibus" v-for="(item1,index1) in item.setting_stage_list">
                                  <a-checkbox :value="item1.mu" @change="onChangeCategoryCheck">{{item1.stage_title}}</a-checkbox>
                                  <span>{{item1.selectedCount}}/{{item1.course_num}}</span>
                                  <a-tag color="blue" @click="_select_lesson_ai(item1,index,index1,0)">编辑</a-tag>
                              </div>
                          </a-tab-pane>
                        </a-tabs>
                      </a-tab-pane>
                      <a-tab-pane key="1" tab="核心课">
                        <a-tabs default-active-key="0" tab-position="top" @prevClick="prevClick" @nextClick="nextClick" @change="_core_category_tab">
                          <a-tab-pane v-for="(item,index) in coreCourseList" :key="index" :tab="item.title" >
                              <div class="aibus" v-for="(item1,index1) in coreCategoryList">
                                  <a-checkbox :value="item1.id" @change="onChangeCategoryCheck">{{item1.title}}</a-checkbox>
                                  <span>{{item1.selectedCount}}/{{item1.all_lesson_count}}</span>
                                  <a-tag color="blue" @click="_select_lesson_core(item1,index1,1)">编辑</a-tag>
                              </div>
                          </a-tab-pane>
                        </a-tabs>
                      </a-tab-pane>
                      <a-tab-pane key="2" tab="拓展">
                            <a-radio-group v-model="expandCourse" @change="onChangExpandCourse">
                                <a-radio style="display: block;height: 30px;line-height: 30px;" v-for="(item,index) in expandCourseList" :value="item.lecture_unique">{{item.title}}</a-radio>
                            </a-radio-group>
                      </a-tab-pane>
                    </a-tabs>
                 </div>
             </div>

             <div class="btn-list" style="margin-top: 100px;">
                    <a-button style="margin-right: 20px;" @click="_hideCancel">取消</a-button>
                    <a-button type="primary" @click="do_save">保存</a-button>
             </div>

             <a-modal v-model="lessonState" :title="lessonTitle" @ok="modalHandleOk" @cancel="modelCancel">
                  <div class="lesson-list">
                      <div class="lesson-all">
                          <a-checkbox :checked="checkAll" @change="onCheckAllChange">全选</a-checkbox>
                      </div>
                      <a-checkbox-group v-model="lesson_select" :options="lesson_checkbox_option" @change="onChangeCheckbox" />
                  </div>
            </a-modal>
        </a-drawer>

    </div>
</template>
<script>
import moment from 'moment';
import QmExchangeEdit from './QmExchange_edit';
export default {
    name:'QmExchange',
    components: {
        QmExchangeEdit,
    },
    data(){
        return{
            columns: [
                { title: '兑换码名称', dataIndex: 'code_package_name', key: 'code_package_name',align:'center',width:300 },
                { title: '兑换类别', dataIndex: 'exchange_type_text', key: 'exchange_type_text',align:'center',width:120,scopedSlots: { customRender: 'exchange_type' }, },
                { title: '兑换内容', dataIndex: 'course_title', key: 'course_title',align:'center',width:300 ,scopedSlots: { customRender: 'course_title' },},
                { title: '发放渠道', dataIndex: 'send_channel_text', key: 'send_channel_text',align:'center',width:130 ,scopedSlots: { customRender: 'send_channel' },},
                { title: '发放总量', dataIndex: 'send_count', key: 'send_count',align:'center',width:100,scopedSlots: { customRender: 'send_count' },},
                { title: '已兑换', dataIndex: 'use_count', key: 'use_count',align:'center',width:100,scopedSlots: { customRender: 'use_count' }, },
                { title: '兑换码期限', dataIndex: 'expire_time', key: 'expire_time',align:'center',width: 300, scopedSlots: { customRender: 'expire_time' }, },
                { title: '状态', dataIndex: 'code_package_state_text', key: 'code_package_state_text',align:'center',width: 120, scopedSlots: { customRender: 'code_package_state' },},
                { title: '操作栏', dataIndex: '', key: 'x', scopedSlots: { customRender: 'action' },align:'center',width: 120 },
            ],

            codeListColumns: [
                { title: '兑换码ID', dataIndex: 'code_id', key: 'code_id',align:'center',width:200 },
                { title: '兑换码号', dataIndex: 'code_name', key: 'code_name',align:'center',width:200,scopedSlots: { customRender: 'exchange_type' }, },
                { title: '创建时间', dataIndex: 'create_time', key: 'create_time',align:'center',width:150 },
                { title: '状态', dataIndex: 'code_state_text', key: 'code_state_text',align:'center',width:100 },
                { title: '操作栏', dataIndex: '', key: 'x', scopedSlots: { customRender: 'action' },align:'center',width: 100 },
            ],

            codeListData:[],

            infoData:[],
            startDate: '',
            endDate: '',
            code_name:'',
            minHeight:'500px',
            total:0,
            page:1,
            ExChangeVisible:false,

            aiCourseSetting:[],
            lessonState:false,
            lessonTitle:'选择课程',
            lesson_mu:'',
            lesson_checkbox_option:[],
            lesson_select:[],
            checkAll: false,
            category_check:false,
            _tmp_index_1:null,
            _tmp_index_2:null,
            _tmp_type_value:null,
            aiCourseList:[],

            coreCourseList:[],
            coreCategoryList:[],
            expandCourseList:[],
            expandCourse:[],

            codeCatogories:[],

            categoryId:'',
            categoryTitle:'',


            codePackageName: '',
            sendCount: 0,
            startTime:'',
            expireTime:'',
            sendChannel: 0,
            exchangeType: 0,
            courseTitle:'',
            courseId:'',

            channelList:[],
            activeKey:null,
            aiCourseIndex:null,

            stopVisible:false,

            serachParams:{
                codePackageName: '',
                codePackageState: '0',
                courseId: '',
                exchangeType: '0',
                expireTime: '',
                startTime: '',
            },

            ExChangeEditVisible:false,
            codePackageId:'',

            showCodeListState:false,
            codeListTitle:'',
            codeListSerachParams:{
                codePackageId:'',
                codeName:'',
                codeState:0,
                page:1,
            },
            codeListTotal:0,
            codeListPage:1,
        }
    },
    created(){
        this._getData();
    },
    mounted(){
         
    },
    methods:{
     moment,
     serachBtn(){
        this.page = 1;
        this._getData();
     },
     onTtableClick(){
        this.ExChangeVisible = true;
        this._init_codeCatogories();
        this._get_tabs_data(3);
        this._getSendChannel();
     },
     resetBtn(){
        this.serachParams = {
            codePackageName: '',
            codePackageState: '0',
            courseId: '',
            exchangeType: '0',
            expireTime: '',
            startTime: '',
        }
        this.page = 1;
        this._getData();
     },
     changeTime(value){
        if (value.length > 0) {
            this.serachParams.startTime = moment(value[0]._d).format('YYYY-MM-DD');
            this.serachParams.expireTime = moment(value[1]._d).format('YYYY-MM-DD');
        } else {
            this.serachParams.startTime = '';
            this.serachParams.expireTime = '';
        }
     },
     pageChange(pageNumber){
        this.page = pageNumber;
        this._getData();
     },
     _getData(){
        this.$message.loading('数据加载中...');
        this.serachParams.page = this.page;
        this.$axios.post(8000127, this.serachParams,res => {
            if(res.data.code==1){
                this.infoData = res.data.data.list;
                this.total = res.data.data.count;
            }else{
                this.infoData = {};
                this.total = 0;
            }
            this.$message.destroy()
        });
     },
     _hideCancel(){
        this.ExChangeVisible = false;
        this.codePackageName = '';
        this.sendCount = 0;
        this.startTime = '';
        this.expireTime = '';
        this.sendChannel =  0;
        this.exchangeType =  0;
        this.courseTitle = '';
        this.courseId = '';
     },
     _hideEditCancel(){
        this.ExChangeEditVisible = false;
     },
     _showEdit(codePackageId,read_only){
        this.ExChangeEditVisible = true;
        this.codePackageId = {
            codePackageId:codePackageId,
            read_only:read_only
        };
     },
     _get_tabs_data(activeKey){
        this.activeKey = activeKey.toString();
        this._init_codeCatogories();
        switch (parseInt(activeKey)) {
            case 3:
                this._getAICourseSetting();
                break;
            case 1:
                this._getCoreCourse();
                break;
            case 2:
                this._getExpandCourse();
                break;
            default:
        }
     },
     _getAICourseSetting(){
        this.$message.loading('数据加载中...');
        this.$axios.post(8000128, {
            pageNum: 1,
            pageSize: 100
        },res => {
            if(res.data.code==1){
                this.aiCourseSetting = res.data.data;
                this.aiCourseSetting.map(function(item,index){
                    item.setting_stage_list.map(function(sta_item,sta_index){
                        sta_item.selectedCount = 0;
                        sta_item.lessonlist = [];
                    });
                });
                this.aiCourseIndex = 0;
                this._set_course_value(
                    this.aiCourseSetting[0].mu,
                    this.aiCourseSetting[0].title
                );
            }else{
                this.aiCourseSetting = [];
            }
            this.$message.destroy()
        });
     },
     _getCoreCourse(){
        this.$axios.post(8000130, {
            pageNum: 1,
            pageSize: 100
        },res => {
            if(res.data.code==1){
                this.coreCourseList = res.data.data;
                this._core_category_tab(0);
            }else{
                this.coreCourseList = [];
            }
        });
     },
     _getCoreCategory(courseUnique){
        this.$axios.post(8000131, {
            courseUnique:courseUnique,
            pageNum: 1,
            pageSize: 100
        },res => {
            if(res.data.code==1){
                this.coreCategoryList = res.data.data;
                this.coreCategoryList.map(function(item,index){
                    item.selectedCount = 0;
                    item.lessonlist = [];
                });
            }else{
                this.coreCategoryList = [];
            }
        });
     },
     _core_category_tab(index){
        this._init_codeCatogories();
        this._getCoreCategory(this.coreCourseList[index].course_unique);
        this._set_course_value(
            this.coreCourseList[index].course_unique,
            this.coreCourseList[index].title
        );
     },
     _getExpandCourse(){
        this.$axios.post(8000132, {
            pageNum: 1,
            pageSize: 100
        },res => {
            if(res.data.code==1){
                this.expandCourseList = res.data.data;
            }else{
                this.expandCourseList = [];
            }
        });
     },
     _select_lesson_ai(_item,i,j,type){
        this.lessonState = true;
        this.lessonTitle = '选择课程-'+_item.stage_title;
        this.set_category_value(_item.mu,_item.stage_title);
        this._set_tmp_value(i,j,type);
        this.aiCourseIndex = i;
        this.$axios.post(8000129, {mu: _item.mu},res => {
            if(res.data.code==1){
                this.aiCourseList = res.data.data;
                if (this.codeCatogories.length > 0) {
                    this.codeCatogories.forEach((item,index) => {
                        if (_item.mu == item.categoryId) {
                            item.lessons.forEach((sta_item,sta_index) => {
                                this.lesson_select.push(sta_item.lessonId);
                            });
                        }
                    });
                }
                this.aiCourseList.forEach((item, index) => {
                    let tmp = {};
                    tmp.label = `第${index + 1}节`;
                    tmp.value = item.mu;
                    this.lesson_checkbox_option.push(tmp);
                })

                this.checkAll = this.aiCourseList.length === this.lesson_select.length;
            }else{
                this.aiCourseList = [];
            }
        });
     },
     _select_lesson_core(_item,i,type){
        this.aiCourseIndex = null;
        this.lessonState = true;
        this.lessonTitle = '选择课程-'+_item.title;
        this.set_category_value(_item.category_unique,_item.title);
        this._set_tmp_value(i,null,type);
        this.$axios.post(8000133, {categoryId: _item.category_unique},res => {
            if(res.data.code==1){
                let _data = res.data.data;

                if (this.codeCatogories.length > 0) {
                    this.codeCatogories.forEach((item,index) => {
                        if (_item.category_unique == item.categoryId) {
                            item.lessons.forEach((sta_item,sta_index) => {
                                this.lesson_select.push(sta_item.lessonId);
                            });
                        }
                    });
                }

                _data.forEach((item, index) => {
                    let tmp = {};
                    tmp.label = `第${index + 1}节`;
                    tmp.value = item.lesson_unique;
                    this.lesson_checkbox_option.push(tmp);
                })

                this.checkAll = _data.length === this.lesson_select.length;
            }else{
                this.lesson_checkbox_option = [];
            }
        });
     },
     _set_tmp_value(i,j,type){
        this._tmp_index_1 = i;
        this._tmp_index_2 = j;
        this._tmp_type_value = type;
     },
     _set_select_count(count,lesson_list){
        if (this._tmp_type_value == null) {return;}
        let i = this._tmp_index_1;
        let j = this._tmp_index_2;
        switch (this._tmp_type_value) {
            case 0:
                this.aiCourseSetting[i].setting_stage_list[j].selectedCount = count;
                this.aiCourseSetting[i].setting_stage_list[j].lessonlist = lesson_list;
                break;
            case 1:
                this.coreCategoryList[i].selectedCount = count;
                this.coreCategoryList[i].lessonlist = lesson_list;
                break;
            default:
                break;
        }
     },
     modalHandleOk(e){
        this.lessonState = false;
        this._clear_lesson();
     },
     modelCancel(e){
        this.codeCatogories = [];
        this._set_tmp_value(null,null,null);
        this._clear_lesson();
     },
     onChangeCheckbox(checkedList){
        let _ids = this._get_lesson_id_all();
        this.checkAll = checkedList.length === _ids.length;
        this._set_codeCatogories(checkedList);
     },
     _set_codeCatogories(checkedList,count_set = true){
        if (checkedList.length > 0) {
            if (count_set) {
                this._set_select_count(checkedList.length,checkedList);
            }
            let codeCatogories;
            if (this.codeCatogories.length > 0) {

                let _tmp;
                let _lst = [];
                this.codeCatogories.forEach((item,index) => {
                    _lst.push(item.categoryId);
                })

                //不存在就创建
                if (_lst.indexOf(this.categoryId) == -1) {
                    _tmp = {
                        categoryId:this.categoryId,
                        categoryTitle:this.categoryTitle,
                        lessons:[]
                    };
                }
                
                if (_tmp) {this.codeCatogories.push(_tmp);}
                
                this.codeCatogories.forEach((item,index) => {
                    if (item.categoryId == this.categoryId) {
                        item.lessons = [];
                        for (var j = 0; j < checkedList.length; j++) {
                            let tmp = {
                                lessonCreateState:1,
                                lessonId:checkedList[j]
                            };
                            item.lessons.push(tmp);
                        }
                    }
                })
            } else {
                codeCatogories = {
                    categoryId:this.categoryId,
                    categoryTitle:this.categoryTitle,
                    lessons:[]
                };
                for (var i = 0; i < checkedList.length; i++) {
                    let tmp = {
                        lessonCreateState:1,
                        lessonId:checkedList[i]
                    };
                    codeCatogories.lessons.push(tmp);
                }
                this.codeCatogories.push(codeCatogories);
            }      
        } else {
            this._clear_codeCatogories_value();
        }
     },
     _clear_codeCatogories_value(){
        let i = this._tmp_index_1;
        let j = this._tmp_index_2;

        //删除
        for (var n = 0; n < this.codeCatogories.length; n++) {
            if (this.codeCatogories[n].categoryId == this.categoryId) {this.codeCatogories.splice(n,1);}
        }

        switch (this._tmp_type_value) {
            case 0:
                this.aiCourseSetting[i].setting_stage_list[j].selectedCount = 0;
                this.aiCourseSetting[i].setting_stage_list[j].lessonlist = [];
                break;
            case 1:
                this.coreCourseList[i].selectedCount = 0;
                this.coreCourseList[i].lessonlist = [];
                break;
            default:
                break;
        }
     },
     onCheckAllChange(e){

        if (e.target.checked) {
            this.lesson_select = this._get_lesson_id_all();
            this.category_check = true;
            this._set_codeCatogories(this.lesson_select);
        } else {
            this.lesson_select = [];
            this.category_check = false;
            this._clear_codeCatogories_value();
        }

        this.checkAll = e.target.checked;
     },
     aiChange(activeKey){
        this._set_course_value(
            this.aiCourseSetting[activeKey].mu,
            this.aiCourseSetting[activeKey].title
        );
        this._clear_lesson();
        this._init_codeCatogories();
     },
     _clear_lesson(){
        this.category_check = this.checkAll = false;
        this.lesson_select = [];
        this.lesson_checkbox_option = [];
     },
     _get_lesson_id_all(){
        if (this.lesson_checkbox_option) {
            let _lst = [];
            this.lesson_checkbox_option.forEach((item,index) => {
                _lst.push(item.value);
            });
            return _lst;
        }
        return [];
     },
     onChangeCategoryCheck(e){
        this.category_check = e.target.checked;
     },
     prevClick(e){
        console.log(e);
     },
     nextClick(e){
        console.log(e);
     },
     onChangExpandCourse(e){
        let _value = e.target.value;
        this._init_codeCatogories();
        for (var i = 0; i < this.expandCourseList.length; i++) {
            if (this.expandCourseList[i].lecture_unique == _value) {
                this.codeCatogories.push({
                    categoryId: _value,
                    categoryTitle: this.expandCourseList[i].title,
                    lessons: []
                })
            }
        }
     },
     _init_codeCatogories(){
        this.codeCatogories = [];
     },
     set_category_value(categoryId,categoryTitle){
        this.categoryId = categoryId;
        this.categoryTitle = categoryTitle;
     },
     do_save(){
        let params = {
            codePackageName: this.codePackageName,
            sendCount: Number(this.sendCount),
            startTime: this.startTime,
            expireTime: this.expireTime,
            sendChannel: this.sendChannel,
            exchangeType: Number(this.activeKey) === 3 ? (this.aiCourseIndex ? 5 : 4) : Number(this.activeKey),
            courseTitle: this.courseTitle,
            courseId: this.courseId,
            codeCatogories:JSON.stringify(this.codeCatogories)
        }

        this.$axios.post(8000135, params,res => {
            if (res.data.code == 1) {
                this._clear_lesson();
                this._init_codeCatogories();
                this._hideCancel();
                this._getData();
            } else {
                this.$message.error(res.data.message);
            }
        });

     },
     _set_course_value(courseId,courseTitle){
        this.courseId = courseId;
        this.courseTitle = courseTitle;
     },
     onChangeUseTime(value){
        if (value.length > 0) {
            this.startTime = moment(value[0]._d).format('YYYY-MM-DD');
            this.expireTime = moment(value[1]._d).format('YYYY-MM-DD');
        } else {
            this.startTime = '';
            this.expireTime = '';
        }
     },
     _getSendChannel(){
        this.$axios.post(8000134, {name: 'sendChannel'},res => {
            if(res.data.code==1){
                this.channelList = res.data.data;
            }else{
                this.channelList = [];
            }
        });
     },
     onChangeChannel(value){
        this.sendChannel = value;
     },
     _updateCodePackageState(record,codePackageState){
        console.log(record);
        let codePackageId = record.code_package_id;
        let that = this;

        let content;

        if (codePackageState == 4) {
            content = '确定要停止兑换码？';
        } else if (codePackageState == 2) {
            content = '确定更改兑换码状态为进行中？';
        }

        this.$confirm({
            title: '温馨提示',
            content: '确定要停止兑换码？',
            onOk() {
                that.$axios.post(8000136, {codePackageId: codePackageId,codePackageState:codePackageState},res => {
                if(res.data.code==1){
                    that._getData();
                }else{
                    that.$message.error('操作失败');
                }
            });
            },
            onCancel() {
                console.log('no');
            },
        });
     },
     onChangExchangetype(value){
        this.serachParams.exchangeType = value;
     },
     onChangCodePackageState(value){
        this.serachParams.codePackageState = value;
     },
     disabledDate(current) {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
     },
     _fathermethod(data){
        this._hideEditCancel();
        this.page = 1;
        this._getData();
     },
     _showCodeList(item,state){
        this.showCodeListState = true;
        this.codeListTitle = item.code_package_name + '-兑换码列表'
        this.codeListSerachParams.codePackageId = item.code_package_id;
        this.codeListSerachParams.codeState = state;
        this.codeSerach();
     },
     _hideCodeList(){
        this.showCodeListState = false;
        this.codeListSerachParams = {
            codePackageId:'',
            codeName:'',
            codeState:0,
            page:1,
        };
        this.codeListTotal = 0;
        this.codeListPage = 1;
     },
     _onCodeListStateChange(value){
        this.codeListSerachParams.codeState = value;
     },
     _getCodeStateText(){
        console.log(this.codeListSerachParams.codeState);
        switch (this.codeListSerachParams.codeState) {
            case 1:
                return '未兑换';
            case 2:
                return '已兑换';
            case 3:
                return '已停用';
            default:
                return 0;
        }
     },
     codeSerach(){
        this.$message.loading('数据加载中...');
        let params = this.codeListSerachParams;
        this.$axios.get(8000139, params,res => {
            if(res.data.code==1){
                this.codeListData = res.data.data;
                this.codeListTotal = res.data.count;
            }else{
                this.codeListData = [];
                this.codeListTotal = 0;
            }
            this.$message.destroy();
        });
     },
     expert_code(){
        this.$message.loading('努力导出中，请稍等...');
        let params = {codePackageId: this.codeListSerachParams.codePackageId};
        this.$axios.post(8000141, params,res => {
            if(res.data.code==1){
                window.open(res.data.url);
            }else{
                this.$message.error(res.data.message);
            }
            this.$message.destroy();
        });
     },
     _codeStop(item){
        let that = this;
        let params = {
            id: item.id,
            state: 3
        };

        this.$confirm({
            title: '温馨提示',
            content: '确定要停用兑换码？',
            onOk() {
                that.$axios.post(8000140, params,res => {
                    if(res.data.code==1){
                        that.codeSerach();
                    }else{
                        that.$message.error(res.data.message);
                    }
                });
            },
            onCancel() {
                console.log('no');
            },
        });
     },
     onChangeCodeListPage(page){
        console.log(page);
        this.codeListSerachParams.page = page;
        this.codeSerach();
     },
    },
}
</script>

<style lang="less" > 
    .Coupon{
         .item-header{
            padding: 15px 20px;
            background: #fff;
            border-radius: 4px;
            .screen-list-item{
                margin-right: 20px;
            }
            .screen-item-label{
                margin-right: 10px;
                color: #666;
                font-size: 14px;
                white-space: nowrap;
            }
        }
        .coupon-content{
            padding: 15px;
            background: #fff;
            margin-top: 20px;
            border-radius: 4px;
            .content-title{
                span{
                    font-size: 18px;
                    color: #333;
                }
                i{
                    margin-left: 10px;
                    font-size: 18px;
                    cursor: pointer;
                }
            }

            .smalldash{
                line-height: 30px;
            }
            .edit-item{
                font-size:12px;
            }
        }
    }
    .Coupon .ant-select-selection--single,.Coupon .ant-input{height: 36px;}
    .Coupon .ant-select-selection__rendered{line-height: 34px;}
    .Coupon .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
        padding: 16px 6px;
    }
    .coupon_form{padding-bottom: 30px;}
    .coupon_form .ant-row .ant-form-item-label{text-align: left;}
    .coupon_form .ant-radio-group .ant-input-number{width: 124px;}
    .coupon_form .ant-input-number{width:330px;}

    .item-list{
        display: flex;
        overflow: hidden;
        margin-bottom: 25px;
        .item-content{
            float: right;
            width: 400px;
        }
        label{
            margin-top: 6px;
            margin-right: 15px;
        }
    }
</style>